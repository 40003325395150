import {
  Avatar,
  Badge,
  Button,
  Flex,
  IconButton,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { CgBlock, CgUnblock } from "react-icons/cg";
import ApproveMemberDialog from "./ApproveMemberDialog";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { axiosPost } from "utils/api";
import { BsLayoutTextSidebarReverse } from "react-icons/bs";
import { useHistory } from "react-router-dom";

function MemberRow(props) {
  const {
    username,
    ip,
    email,
    status,
    date,
    isLast,
    fullname,
    phone,
    isBlockComment,
    memberId,
    refetch,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const history = useHistory();

  function handleRowClick() {
    onOpen();
  }

  const handleBlockChat = async () => {
    if (
      window.confirm(
        `Are you sure to ${
          isBlockComment ? "unblock" : "block"
        } chat this member?`
      )
    ) {
      const data = {
        memberId,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.BLOCK_CHAT_MEMBER,
          data
        );
        if (response?.data?.code === 0) {
          toast({
            title: "Block member Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          refetch?.();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Block member Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {username}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {fullname}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {phone}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {ip}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {date}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex>
            <Tooltip
              label={isBlockComment ? "Unblock chat" : "Block chat"}
              placement="top"
            >
              <IconButton p={2} bg="transparent" onClick={handleBlockChat}>
                {isBlockComment ? (
                  <CgUnblock size={20} />
                ) : (
                  <CgBlock size={22} />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip label={"History mission"} placement="top">
              <IconButton
                p={2}
                bg="transparent"
                onClick={() =>
                  history.push(`/admin/mission-member/${memberId}`)
                }
              >
                <BsLayoutTextSidebarReverse size={20} />
              </IconButton>
            </Tooltip>
          </Flex>
        </Td>

        {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Badge
            bg={status === "Online" ? "green.400" : bgStatus}
            color={status === "Online" ? "white" : "white"}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {status}
          </Badge>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Button
            p="0px"
            bg="transparent"
            variant="no-effects"
            onClick={status !== "approved" && handleRowClick}
          >
            <Text
              fontSize="md"
              color="gray.400"
              fontWeight="bold"
              cursor="pointer"
            >
              {status !== "approved" ? "Approve" : "-"}
            </Text>
          </Button>
        </Td> */}
      </Tr>
      <ApproveMemberDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        fetchData={refetch}
        userName={username}
      />
    </>
  );
}

export default MemberRow;
