import {
  Button,
  Flex,
  Tag,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { Select } from "chakra-react-select";
import {
  ROOT_API,
  API_ROUTES,
  ModalType,
  SeasonStatusOption,
  MISSION_BONUS_TYPE,
  MISSION_TYPE,
  MISSION_DAILY,
  DAY_OF_WEEK,
  MISSION_BONUS_TYPE_VALUE,
  MISSION_DAILY_VALUE,
  MISSION_TIMES,
} from "utils/constant";
import { axiosPost } from "utils/api";
import moment from "moment";

function SeasonTableRow({ row, isLast, refetchData, handelClickRow }) {
  const history = useHistory();
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    if (row?.status) {
      setStatus(SeasonStatusOption.find((item) => item.value === row?.status));
      return;
    }

    return setStatus(SeasonStatusOption[0]);
  }, [row?.status]);

  const handleDeleteClick = async () => {
    if (window.confirm("Are you sure to delete this season?")) {
      const data = {
        id: row._id,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.DELETE_SEASON,
          data
        );
        if (response?.data?.code === 0) {
          toast({
            title: "Delete League Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          refetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete League Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const handleChangeStatus = async (optionSelected) => {
    if (window.confirm("Are you want to change status this season?")) {
      const data = {
        id: row._id,
        status: optionSelected?.value,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.UPDATE_STATUS_SEASON,
          data
        );
        if (response?.data?.code === 0) {
          toast({
            title: "Change status Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setStatus(optionSelected);
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Change status Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.username}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {MISSION_BONUS_TYPE[row?.type] || MISSION_TYPE[row?.type]}{" "}
              {!!MISSION_DAILY[row?.dailyType]
                ? "(" + MISSION_DAILY[row?.dailyType] + ")"
                : ""}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text
              fontSize="md"
              color="blue.500"
              cursor="pointer"
              fontWeight="bold"
              onClick={() => {
                // history.push(`/admin/season/${row?._id}`);
              }}
            >
              {row?.minutes || "-"}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.type === MISSION_BONUS_TYPE_VALUE.INVITE_MEMBER_BONUS
                ? `${row?.numberFriend}/${
                    row?.missionBonus?.numberFriend || "-"
                  }`
                : row?.dailyType === MISSION_DAILY_VALUE.INVITE_MEMBER
                ? `${row?.numberFriend}/${row?.mission?.times || "-"}`
                : "-"}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.numberShare || "-"}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.dailyType === MISSION_DAILY_VALUE.SHARE
                ? `${row?.numberShare}/${row?.mission?.times || "-"}`
                : row?.dailyType === MISSION_DAILY_VALUE.JOIN_CHAT_ROOM
                ? `${row?.times || 1}/${row?.mission?.times || "-"}`
                : "-"}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.mission?.dayOfWeek
                ? DAY_OF_WEEK[row?.mission?.dayOfWeek]
                : "-"}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.point}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Tag
              fontSize="md"
              colorScheme={row?.isCollected ? `green` : "red"}
              fontWeight="bold"
              isTruncated
            >
              {row?.isCollected ? "Đã nhận" : "Chưa nhận"}
            </Tag>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Tag
              fontSize="md"
              colorScheme={row?.isCompleted ? `green` : "red"}
              fontWeight="bold"
              isTruncated
            >
              {row?.isCompleted ? "Đã hoàn thành" : "Chưa hoàn thành"}
            </Tag>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold" isTruncated>
              {moment(row?.date).format("DD-MM-YYYY HH:mm")}
            </Text>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default SeasonTableRow;
