import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  useToast,
  Flex,
  Text,
  useColorModeValue,
  Switch,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { pick } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import InputController from "components/Form/InputController";
import {
  ValidateMessage,
  ROOT_API,
  API_ROUTES,
  MISSION_BONUS_TYPE,
  MISSION_DAILY,
} from "utils/constant";
import SelectController from "components/Form/SelectController";
import { mappingMiniLeagueOption } from "utils/mapping";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const CreateMissionBonus = ({ seasonDetail, onClose, refetchData }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const history = useHistory();
  const cancelRef = useRef();
  const toast = useToast();
  const params = useParams();
  const query = useLocation();
  const { missionType, point, nf, times } = queryString.parse(query?.search);
  const { id } = params || {};
  const [isPublic, setIsPublic] = useState(false);
  const [{ loading: createLoading }, createMissionApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CREATE_MISSION_BONUS,
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updatePostApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CREATE_MISSION_BONUS,
    },
    { manual: true }
  );
  const [, publicPostApi] = useAxios(
    {
      method: "post",
      url: `${ROOT_API}${API_ROUTES.STATUS_MISSION}`,
    },
    { manual: true }
  );
  const [{ loading: loadingPostDetail, data }, getPostDetailApi] = useAxios(
    {
      url: `${ROOT_API}${API_ROUTES.MISSIONS}/${id}`,
    },
    { manual: true }
  );

  useEffect(
    () => () => {
      reset();
    },
    []
  );

  useEffect(() => {
    if (!!missionType && !!point) {
      reset({
        type: Object.keys(MISSION_BONUS_TYPE)
          .map((key) => ({
            label: MISSION_BONUS_TYPE[key],
            value: key,
          }))
          .find((item) => item.value === missionType),
        point: point,
        numberFriend: Number(nf),
        times: Number(times),
      });
    }
  }, [missionType, point]);

  const schema = yup.object().shape({
    type: yup.object().nullable().required(ValidateMessage.required),
    point: yup.string().nullable().required(ValidateMessage.required),
    times: yup
      .string()
      .nullable()
      .when("type", {
        is: (type) => (type?.value !== "INVITE_MEMBER_BONUS" ? true : false),
        then: () => yup.string().nullable().required(ValidateMessage.required),
      }),
    numberFriend: yup
      .string()
      .nullable()
      .when("type", {
        is: (type) => (type?.value === "INVITE_MEMBER_BONUS" ? true : false),
        then: () => yup.string().nullable().required(ValidateMessage.required),
      }),
  });
  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: undefined,
      point: "",
    },
  });
  const type = watch("type");

  const handleSuccess = () => {
    refetchData?.();
    toast({
      title: `${
        point && missionType ? "Edit" : "Create"
      } Mission Bonus Successfully`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    history.goBack();
  };

  const handleError = (error) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${point && missionType ? "Edit" : "Create"} Mission Bonus Fail`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = (dataForm) => {
    const payload = {
      missionType: dataForm?.type?.value,
      point: Number(dataForm?.point),
      ...(type?.value === "INVITE_MEMBER_BONUS" && {
        numberFriend: Number(dataForm?.numberFriend),
      }),
      ...(type?.value !== "INVITE_MEMBER_BONUS" && {
        times: Number(dataForm?.times),
      }),
    };
    if (point && missionType) {
      updatePostApi({
        data: { ...payload, missionId: id },
      })
        .then(() => {
          handleSuccess();
        })
        .catch((error) => {
          handleError(error);
        });

      return;
    }

    createMissionApi({
      data: payload,
    })
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handlePublicPost = (status) => {
    publicPostApi({
      data: {
        missionId: id,
      },
    })
      .then(() => {
        toast({
          title: "Change Status Mission Bonus Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Change Status Mission Bonus Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    setIsPublic(status);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Flex justifyContent="space-between">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {`${id ? "Edit" : "Create"} Mission Bonus`}
            </Text>
            {id && (
              <Switch
                size="md"
                isChecked={isPublic}
                onChange={(e) => handlePublicPost(e.target.checked)}
              />
            )}
          </Flex>
        </CardHeader>
        <CardBody pb={4}>
          <form>
            <SelectController
              control={control}
              name="type"
              label="Loại"
              isRequired
              options={Object.keys(MISSION_BONUS_TYPE).map((key) => ({
                label: MISSION_BONUS_TYPE[key],
                value: key,
              }))}
              onChange={() => {
                reset((values) => ({
                  ...values,
                  minutes: "",
                  dayOfWeek: undefined,
                  dailyType: undefined,
                  times: "",
                }));
              }}
              isDisabled={!!point && !!missionType}
            />
            <InputController
              control={control}
              name="point"
              label="Điểm"
              type="number"
              isRequired
              styleContainer={{ pt: "4" }}
            />
            {type?.value !== "INVITE_MEMBER_BONUS" && (
              <InputController
                control={control}
                name="times"
                label="Số lần"
                type="number"
                isRequired
                styleContainer={{ pt: "4" }}
              />
            )}
            {type?.value === "INVITE_MEMBER_BONUS" && (
              <InputController
                control={control}
                name="numberFriend"
                label="Số lượng mời bạn"
                type="number"
                isRequired
                styleContainer={{ pt: "4" }}
              />
            )}
          </form>
          <Flex pt={4} alignItems="flex-end" justifyContent="flex-end">
            <Button
              ref={cancelRef}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              isLoading={createLoading || updateLoading}
              onClick={handleSubmit(onSubmit)}
            >
              OK
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CreateMissionBonus;
