import React, { useRef, useState } from "react";
import {
  Flex,
  Table,
  Button,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Input,
  InputGroup,
  Box,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { MISSION_DAILY, MISSION_TYPE, ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import useAxios from "axios-hooks";
import { initialFilter } from "utils/constant";
import SeasonTableRow from "./components/Row";
import { TablePagination } from "@trendmicro/react-paginations";
import Loading from "components/Layout/Loading";
import { Select } from "chakra-react-select";

const Missions = () => {
  const history = useHistory();
  const refSearchButton = useRef(null);
  const [filter, setFilter] = useState(initialFilter);
  const [searchValue, setSearchValue] = useState("");
  const [point, setPoint] = useState(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [sort, setSort] = useState({
    type: null,
    dailyType: null,
  });

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${ROOT_API}${API_ROUTES.MISSIONS}`,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  const [{ loading: createInitDataLoading }, createInitData] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.INIT_DATA_MISSION,
    },
    { manual: true }
  );

  const handleInitDataMission = () => {
    createInitData({
      data: undefined,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            `Init data mission fail`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Missions
            </Text>
          </CardHeader>
          <CardBody pb={4} overflowX="hidden">
            <Flex mb={3} columnGap={2} justifyContent={"space-between"}>
              <Box display={"flex"} alignItems={"center"} gap={5}>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    history.push("/admin/mission/create");
                  }}
                >
                  Tạo nhiệm vụ mới
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    history.push("/admin/mission-bonus");
                  }}
                >
                  Quản lí điểm thưởng
                </Button>
                <Button
                  colorScheme="blue"
                  isLoading={createInitDataLoading}
                  onClick={handleInitDataMission}
                >
                  Khởi tạo dữ liệu nhiệm vụ
                </Button>
              </Box>
            </Flex>
            <Flex gap={3} my={8} flexWrap={"wrap"}>
              <InputGroup w={"300px"}>
                <Input
                  type="text"
                  variant="outline"
                  placeholder="Tìm kiếm theo tên"
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === "Enter" && event?.target?.value) {
                      const searchBtn = refSearchButton?.current;
                      if (searchBtn !== null) {
                        searchBtn.click();
                      }
                    }
                  }}
                />
              </InputGroup>
              <InputGroup w={"300px"}>
                <Input
                  type="number"
                  variant="outline"
                  placeholder="Tìm kiếm theo số điểm"
                  value={point}
                  onChange={(event) => setPoint(event.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === "Enter" && event?.target?.value) {
                      const searchBtn = refSearchButton?.current;
                      if (searchBtn !== null) {
                        searchBtn.click();
                      }
                    }
                  }}
                />
              </InputGroup>
              <Flex>
                <Select
                  placeholder="Sắp xếp theo"
                  isClearable
                  value={sort.type}
                  menuShouldBlockScroll
                  onChange={(e) => {
                    setSort({
                      ...sort,
                      type: e,
                    });
                  }}
                  options={Object.keys(MISSION_TYPE).map((key) => ({
                    label: MISSION_TYPE[key],
                    value: key,
                  }))}
                ></Select>
              </Flex>
              <Flex>
                <Select
                  placeholder="Sắp xếp theo"
                  isClearable
                  value={sort.dailyType}
                  menuShouldBlockScroll
                  onChange={(e) => {
                    setSort({
                      ...sort,
                      dailyType: e,
                    });
                  }}
                  options={Object.keys(MISSION_DAILY).map((key) => ({
                    label: MISSION_DAILY[key],
                    value: key,
                  }))}
                ></Select>
              </Flex>
              <Button
                ref={refSearchButton}
                colorScheme="blue"
                onClick={() => {
                  setFilter({
                    ...filter,
                    pageIndex: 0,
                    searchKeyword: searchValue,
                    point: Number(point),
                    type: sort.type?.value,
                    dailyType: sort.dailyType?.value,
                  });
                }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  setSearchValue("");
                  setPoint("");
                  setSort({
                    type: null,
                    dailyType: null,
                  });
                  setFilter({
                    pageIndex: 0,
                    pageSize: 10,
                  });
                }}
              >
                Reset
              </Button>
            </Flex>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Box overflowX={"scroll"}>
                  <Table variant="simple" color={textColor}>
                    <Thead>
                      <Tr my=".8rem" pl="0px" color="gray.400">
                        <Th borderColor={borderColor} color="gray.400">
                          Tên
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Loại
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Điểm
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Thưởng
                        </Th>
                        <Th
                          borderColor={borderColor}
                          color="gray.400"
                          isTruncated
                        >
                          Ngày trong tuần
                        </Th>
                        <Th
                          borderColor={borderColor}
                          color="gray.400"
                          isTruncated
                        >
                          Số lần
                        </Th>
                        <Th
                          borderColor={borderColor}
                          color="gray.400"
                          isTruncated
                        >
                          Số phút xem live
                        </Th>
                        <Th
                          borderColor={borderColor}
                          color="gray.400"
                          isTruncated
                        >
                          Các loại nhiệm vụ
                        </Th>
                        <Th
                          borderColor={borderColor}
                          color="gray.400"
                          isTruncated
                        >
                          Ngày tạo
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Thao tác
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.data?.length
                        ? data?.data?.map((row, index, arr) => {
                            return (
                              <SeasonTableRow
                                row={row}
                                isLast={index === arr.length - 1 ? true : false}
                                key={index}
                                refetchData={refetch}
                                //   handelClickRow={handelClickRow}
                              />
                            );
                          })
                        : ""}
                    </Tbody>
                  </Table>
                </Box>
                <Flex justifyContent={"flex-end"}>
                  <TablePagination
                    type="full"
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={({ page, pageLength }) => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default Missions;
