export const Roles = {
  ADMIN: "admin",
  GUEST: "guest",
  GUIDE: "guide",
  USER: "user",
  CUSTOMER_CARE: "customer_care",
  COMMENTATOR: "commentator",
  MANAGER: "manager",
};

export const RoleOption = Object.keys(Roles).map((key) => ({
  label: Roles[key],
  value: Roles[key],
}));

export const ROOM_CHAT = {
  HOME: "HOME",
  BET: "BET",
};

export const CountryCodeDefault = {
  GB: "GB",
};

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

export const STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECT: "reject",
};

export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

export const defautlPassword = "2Qlive2023@";
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 300;

export const TINY_API_KEY = "t68lq4bzamga3l7ja6smk6fxzn54cib6g1p2o9l5tz9ze2nd";
export const ROOT_API = process.env.REACT_APP_API_HOST;
export const SITE_URLS = process.env.REACT_APP_DOMAIN_SITE.split(",");
export const SITE_URL = "https://2q.live/";
export const SITE_URL_1 = "https://banh.live/";
export const SITE_URL_2 = "https://tructiep2q.com/";
export const STORE_API = "https://api-store.go9.me";
export const STORE_LOAD_API = "https://cdn-stream.go9.me/";
export const API_ROUTES = {
  LIVE_MATCHES: "/api/v1/match/live-matches-bo",
  CALENDAR_MATCHES: "/api/v1/match/calendar-matches",
  LEAGUES: "/api/v1/league/leagues-bo",
  DELETE_MEDIA: "/api/v1/medias/delete",
  MEDIA: "/api/v1/medias/my-collection",
  CREATE_TOP_LEAGUES: "/api/v1/league/create-store",
  UPDATE_LEAGUES: "/api/v1/league/update-status",
  DELETE_LEAGUES: "/api/v1/league/delete",
  CREATE_LEAGUE: "/api/v1/league/leagues",
  COUNTRIES: "/api/v1/countryCode/all-countries",
  LEAGUES_BY_COUNTRY: "/api/v1/leagues",
  LIVE_MATCHES_MONTHLY: "/api/v1/live-matches-monthly",
  MEMBERS_MONTHLY: "/api/v1/members-monthly",
  VIEWERS_MONTHLY: "/api/v1/viewers-monthly",
  LEAGUES_FILTER: "/api/v1/league/leagues",
  UPDATE_LINK_LIVE: "/api/v1/match/update-link-live",
  REMOVE_LINK_LIVE: "/api/v1/match/remove-live-match",
  OPEN_LINK_LIVE: "/api/v1/match/set-open",
  MINI_LEAGUE: "/api/v1/league/leagues-mini",
  CREATE_MINI_LEAGUE: "/api/v1/league/create",
  UPDATE_MINI_LEAGUE: "/api/v1/league/update",
  CREATE_MATCH_MANUAL: "/api/v1/match/create",
  UPDATE_MATCH_MANUAL: "/api/v1/match/update",
  UPDATE_SCORE: "/api/v1/match/update-score",
  DELETE_MATCH: "/api/v1/match/delete",
  SEASONS: "/api/v1/seasons",
  CREATE_SEASON: "/api/v1/seasons/create",
  UPDATE_SEASON: "/api/v1/seasons/update",
  DELETE_SEASON: "/api/v1/seasons/delete",
  SEASON_OF_LEAGUE: "/api/v1/league-seasons",
  GROUPS: "/api/v1/groups",
  CREATE_GROUP: "/api/v1/groups/create",
  UPDATE_GROUP: "/api/v1/groups/update",
  DELETE_GROUP: "/api/v1/groups/delete",
  GROUP_OF_SEASON: "/api/v1/season-groups",
  TEAMS: "/api/v1/teams",
  TEAM_OF_GROUP: "/api/v1/group-teams",
  TEAM_OF_SEASON: "/api/v1/season-teams",
  TEAM_OF_SEASON_FOR_MATCH: "/api/v1/teams-for-match",
  ADD_TEAM_INTO_SEASON: "/api/v1/seasons/add-team",
  ADD_TEAM_INTO_GROUP: "/api/v1/groups/add-team",
  UPDATE_TEAM: "/api/v1/teams/update",
  CREATE_TEAM: "/api/v1/teams/create",
  DELETE_TEAM: "/api/v1/teams/delete",
  SEASON_ROUND: "/api/v1/season-rounds",
  CREATE_MINI_MATCH: "/api/v1/match/create-mini-match",
  UPDATE_MINI_MATCH: "/api/v1/match/update-mini-match",
  MATCHES_ROUND_SEASON: "/api/v1/match/rounds-matches",
  POSTS: "/api/v1/posts-2q",
  CREATE_POST: "/api/v1/posts-2q/create",
  UPDATE_POST: "/api/v1/posts-2q/update",
  DELETE_POST: "/api/v1/posts-2q/delete",
  PUBLIC_POST: "/api/v1/posts-2q/change-show",
  UPLOAD_IMAGE: "/api/v1/posts-2q/upload-image",
  UPLOAD_VIDEO: "/api/v1/posts-2q/upload-video",
  UPDATE_RANK: "/api/v1/ranks/update-rank",
  UPDATE_STATUS_SEASON: "/api/v1/seasons/update-status",
  UPDATE_TEAM_RESULT: "/api/v1/ranks/update-rank-fail",
  UPLOAD_THUMBNAIL: "/api/v1/contents/upload-thumbnail",
  UPLOAD_VIDEO_MEDIA: "/api/v1/medias/upload",
  USER_UPLOAD_THUMBNAIL: "/api/v1/user/upload-thumbnail",
  UPDATE_STATUS_MATCH: "/api/v1/match/update-status",
  RESET_PASS_USER: "/api/v1/user/reset-password",
  UPLOAD_BANNER_ADS: "/api/v1/ads",
  MARQUEE: "/api/v1/marquee",
  DELETE_BANNER_ADS: "/api/v1/ads/delete",
  MATCH_CDN: "/api/v1/match/calendar-matches-cdn",
  LIVE_MATCH_CDN: "/api/v1/match/live-matches-cdn",
  STOP_LIVE_MATCH_CDN: "/api/v1/match-cdn/end-review",
  SET_LINK_MATCH_CDN: "/api/v1/match-cdn/set-up",
  UPLOAD_AVATAR_USER: "/api/v1/user/upload-avatar",
  GET_COMMENTATOR: "/api/v1/user/commentators",
  GET_CDN_LINK: "/api/v1/linksLiveStream",
  ASSIGN_CDN_LINK: "/api/v1/user/add-cdn-links",
  USER_PROFILE: "/api/v1/user/me",
  SET_HOT_MATCH: "/api/v1/match-cdn/set-hot",
  UPLOAD_FILE_STORE: "/api/v1/files/upload",
  BLOCK_CHAT_MEMBER: "/api/v1/block-comment",
  UPDATE_LICK_CDN: "/api/v1/user/update-cdn-links",
  TRACKING_HISTORY: "/api/v1/user/list-action",
  GET_USER_LIST: "/api/v1/user/users",
  EXPORT_MATCHES: "/api/v1/match/export-matches-cdn",
  UPDATE_TIME: "/api/v1/match-cdn/update-time",
  MATCH_LIVING: "/api/v1/match-cdn-live/live-matches-in-day",
  MATCH_CDN_HOT: "/api/v1/match-cdn-hot",
  CUSTOMER_CARE: "/api/v1/customer-care",
  PIN_MESSAGE: "/api/v1/messages/create-pin-msg",
  TRACKING_USER: "/api/v1/sessions/statistics",
  TRACKING_CHAT: "/api/v1/chat/statistics",
  EXPORT_MESSAGE: "/api/v1/chat/export",
  CREATE_MATCH_CDN_MANUAL: "/api/v1/match-cdn/create-match-manual",
  DELETE_USER: "/api/v1/user/delete-user",
  SET_PRIORITY_MATCH: "/api/v1/match-cdn/set-priority",
  SET_PRIORITY_LEAGUE: "/api/v1/league/update-priority",
  SET_OPEN_MATCH: "/api/v1/match-cdn/setting-intro-video",
  SET_ORDER_COMMENTATOR: "/api/v1/user/set-is-hot-cmt",
  CREATE_TIP: "/api/v1/tips/create-tip",
  ADD_SEO_LEAGUE: "/api/v1/match-cdn/add-link-seo",
  BOOKMAKERS: "/api/v1/bookmakers/all",
  SET_PRIORITY_BOOKMAKERS: "/api/v1/bookmakers/set-priority",
  MISSIONS: "/api/v1/mission/missions",
  MISSIONBONUS: "/api/v1/mission-bonus",
  CREATE_MISSION_BONUS: "/api/v1/mission-bonus/create",
  DELETE_MISSION_BONUS: "/api/v1/mission-bonus/delete",
  CREATE_MISSIONS: "/api/v1/mission/create",
  UPDATE_MISSIONS: "/api/v1/mission/update",
  DELETE_MISSION: "/api/v1/mission/delete",
  STATUS_MISSION: "/api/v1/mission/update-status",
  SET_INTERACT: "/api/v1/commentators/set-interact",
  INIT_DATA_MISSION: "/api/v1/mission/init",
  HISTORY_MISSION: "/api/v1/m-mission/all",
};

export const FileImageValid = ["png", "jpeg", "jpg"];

export const ValidateMessage = {
  required: "The field is not empty",
  minNumber: "Must be greater than or equal to 1",
  mustNumber: "The field must be a number",
};

export const ModalType = {
  Add: "add",
  Delete: "delete",
  ChangeStatus: "changeStatus",
  EmailBackup: "emailBackup",
  Import: "import",
  Preview: "preview",
  Detail: "detail",
  ResetPassword: "resetPassword",
  UpdateRank: "updateRank",
};

export const SeasonTypeKey = {
  Cup: "CUP",
  League: "LEAGUE",
};

export const SeasonTypeOption = [
  {
    label: "Cup",
    value: SeasonTypeKey.Cup,
  },
  {
    label: "League",
    value: SeasonTypeKey.League,
  },
];

export const SeasonStatus = {
  OnGoing: "ON_GOING",
  UpComing: "UP_COMING",
  End: "END",
};

export const SeasonStatusText = {
  [SeasonStatus.OnGoing]: "On Going",
  [SeasonStatus.UpComing]: "Up coming",
  [SeasonStatus.End]: "End",
};

export const SeasonStatusOption = [
  {
    label: SeasonStatusText[SeasonStatus.UpComing],
    value: SeasonStatus.UpComing,
  },
  {
    label: SeasonStatusText[SeasonStatus.OnGoing],
    value: SeasonStatus.OnGoing,
  },
  {
    label: SeasonStatusText[SeasonStatus.End],
    value: SeasonStatus.End,
  },
];

export const MATCH_STATUS = {
  NS: "Not Started",
  FT: "Match Finished",
  CANC: "Match Cancelled",
  LIVE: "In Progress",
};

export const REQUEST_CRONJOB_STATUS = {
  "1H": "First Half, Kick Off",
  "2H": "Second Half, 2nd Half Started",
  HT: "Halftime",
  ET: "Extra Time",
  BT: "Break Time",
  P: "Penalty In Progress",
  NS: "Not Started",
  FT: "Finished in the regular time",
  AET: "Finished after extra time without going to the penalty shootout",
  PEN: "Finished after the penalty shootout",
};

export const LEAGUE_NAMESPACE = {
  euro: "Euro",
  copa: "Copa America",
};

export const PostType = {
  Highlight: "HIGHLIGHT",
  News: "NEWS",
  Discuss: "DISCUSS",
  HotNews: "HOT_NEWS",
  Bet: "BET",
  Schedule: "SCHEDULE",
};

export const PostTypeOption = [
  {
    label: "Highlight",
    value: PostType.Highlight,
  },
  {
    label: "Tin Mới",
    value: PostType.News,
  },
  {
    label: "Nhận Định",
    value: PostType.Bet,
  },
  {
    label: "Lịch Thi Đấu",
    value: PostType.Schedule,
  },
  {
    label: "Bet",
    value: PostType.Bet,
  },
];

export const KnockoutOption = [
  {
    label: "2",
    value: "2",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "16",
    value: "16",
  },
];

export const MatchResultStatus = {
  WIN: "WIN",
  DRAW: "DRAW",
  LOSE: "LOSE",
};

export const Points = {
  [MatchResultStatus.WIN]: 3,
  [MatchResultStatus.DRAW]: 1,
  [MatchResultStatus.LOSE]: 0,
};

export const MatchResultOption = [
  {
    label: "Win",
    value: MatchResultStatus.WIN,
  },
  {
    label: "Draw",
    value: MatchResultStatus.DRAW,
  },
  {
    label: "Lose",
    value: MatchResultStatus.LOSE,
  },
];

export const RoundResults = [
  {
    label: "Playing",
    value: "PLAYING",
  },
  {
    label: "Eliminated",
    value: "ELIMINATED",
  },
];
export const ContentTypeVi = {
  NEWS: "Tin Mới",
  // Discuss: "DISCUSS",
  INTRO: "Giới Thiệu",
  DISCUSS: "Soi Kèo",
};
export const ContentType = {
  News: "NEWS",
  Discuss: "DISCUSS",
  Intro: "INTRO",
  Bet: "DISCUSS",
};

export const ContentTypeOption = [
  {
    label: "Tin Mới",
    value: ContentType.News,
  },
  // {
  //   label: "Nhận Định",
  //   value: ContentType.Discuss,
  // },
  {
    label: "Soi Kèo",
    value: ContentType.Discuss,
  },
  {
    label: "Giới Thiệu",
    value: ContentType.Intro,
  },
];
export const FILE_TOKEN =
  "sTQZ2jiTwu7f4vKU6Iqd4cRRh2K5RvYxmPSHL0cryVtTeX183Kg3rAip08iJFyfx";

export const MediaTypeOption = [
  {
    label: "Tin Mới",
    value: ContentType.News,
  },
  {
    label: "Nhận Định",
    value: ContentType.Discuss,
  },
  {
    label: "Soi Kèo",
    value: ContentType.Discuss,
  },
  {
    label: "Giới Thiệu",
    value: ContentType.Intro,
  },
];

export const MISSION_TYPE = {
  COUNTDOWN: "Nhiệm vụ xem live",
  DAILY: "Nhiệm vụ hàng ngày",
  ROLL_CALL: "Nhiệm vụ đăng nhập hàng ngày",
};
export const MISSION_TYPE_VALUE = {
  COUNTDOWN: "COUNTDOWN",
  DAILY: "DAILY",
  ROLL_CALL: "ROLL_CALL",
};

export const MISSION_BONUS_TYPE = {
  ROLL_CALL_BONUS: "Điểm thưởng NV đăng nhập hàng ngày",
  DAILY_BONUS: "Điểm thưởng NV hàng ngày",
  INVITE_MEMBER_BONUS: "Điểm thưởng NV mời thành viên",
};

export const MISSION_BONUS_TYPE_VALUE = {
  ROLL_CALL_BONUS: "ROLL_CALL_BONUS",
  DAILY_BONUS: "DAILY_BONUS",
  INVITE_MEMBER_BONUS: "INVITE_MEMBER_BONUS",
};


export const MISSION_DAILY = {
  INVITE_MEMBER: "Mời thành viên",
  SHARE: "Chia sẻ",
  JOIN_CHAT_ROOM: "Tham gia phòng chat",
  VIEW_LIVE_DISCUSS: "Xem thảo luận trực tiếp",
};

export const MISSION_DAILY_VALUE = {
  INVITE_MEMBER: "INVITE_MEMBER",
  SHARE: "SHARE",
  JOIN_CHAT_ROOM: "JOIN_CHAT_ROOM",
  VIEW_LIVE_DISCUSS: "VIEW_LIVE_DISCUSS",
};

export const MISSION_TIMES = {
  SHARE: "SHARE",
  JOIN_CHAT_ROOM: "JOIN_CHAT_ROOM",
};

export const DAY_OF_WEEK = {
  7: "Chủ nhật",
  1: "Thứ hai",
  2: "Thứ ba",
  3: "Thứ tư",
  4: "Thứ năm",
  5: "Thứ sáu",
  6: "Thứ bảy",
};
