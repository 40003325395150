import {
  Avatar,
  Badge,
  Button,
  Flex,
  IconButton,
  Td,
  Text,
  Tr,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { axiosPost } from "utils/api";
import { DeleteIcon, EditIcon, LinkIcon, StarIcon } from "@chakra-ui/icons";
import { API_ROUTES, STATUS } from "utils/constant";
import AddLinkDialog from "./AddLinkDialog";
import AddCdnLinkCustomDialog from "./AddCdnLinkCustomDialog";
import { ROOT_API } from "utils/constant";
import { GoListOrdered } from "react-icons/go";
import { FaCopy, FaUpload } from "react-icons/fa6";
import KolPriorityDialog from "./KolPriorityDialog";
import { MdDone, MdLiveTv, MdStopScreenShare } from "react-icons/md";
import UploadThumbnail from "./UploadThumbnail";
import UpdateLiveMatch from "./UpdateLiveMatch";
import KolInteractDialog from "./KolInteractDialog";
import { IoLink } from "react-icons/io5";

function KolRow(props) {
  const {
    id,
    name,
    avatar,
    thumbnail,
    isLast,
    fetchData,
    cdnLinks,
    rtmp,
    cdnM3u8Link,
    streamKey,
    streamUrl,
    order,
    isTipCommentator,
    kolData,
    videoDiscuss,
    numberDislike,
    numberLike,
    numberHeart,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const approvedColor = useColorModeValue("green.600", "green.900");
  const pendingColor = useColorModeValue("red.500", "red.900");
  const deleteKolApi = ROOT_API + API_ROUTES.DELETE_USER;
  const setLiveBet = ROOT_API + API_ROUTES.CREATE_TIP;
  const toast = useToast();
  const {
    isOpen: isRegisterOpen,
    onOpen: onRegisterOpen,
    onClose: onRegisterClose,
  } = useDisclosure();
  const {
    isOpen: isUploadThumbnailOpen,
    onOpen: onUploadThumbnailOpen,
    onClose: onUploadThumbnailClose,
  } = useDisclosure();
  const {
    isOpen: isAddCdnLinkOpen,
    onOpen: onAddCdnLinkOpen,
    onClose: onAddCdnLinkClose,
  } = useDisclosure();
  const {
    isOpen: isUpdatePriorityOpen,
    onOpen: onUpdatePriorityOpen,
    onClose: onUpdatePriorityClose,
  } = useDisclosure();
  const {
    isOpen: isLiveMatchOpen,
    onOpen: onLiveMatchOpen,
    onClose: onLiveMatchClose,
  } = useDisclosure();
  const {
    isOpen: isInteractDialogOpen,
    onOpen: onInteractDialogOpen,
    onClose: onInteractDialogClose,
  } = useDisclosure();
  const { hasCopied, onCopy } = useClipboard(streamKey);
  const { hasCopied: hasCopiedUrl, onCopy: onCopyUrl } = useClipboard(
    streamUrl
  );

  function handleRowClick() {
    onRegisterOpen();
  }

  async function handleDeleteClick() {
    if (window.confirm("Are you sure to delete the kol?")) {
      const payload = {
        id,
      };
      try {
        const response = await axiosPost(deleteKolApi, payload);
        if (response?.data?.code == 0) {
          toast({
            title: "Delete Commentator Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete Commentator Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }
  async function handleLiveBet() {
    kolData;
    if (
      window.confirm(
        `Bạn có chắc chắn muốn ${
          !isTipCommentator ? "phát" : "đóng"
        } trực tiếp nhận định cho BLV ?`
      )
    ) {
      const payload = {
        commentatorId: id,
      };
      try {
        const response = await axiosPost(setLiveBet, payload);
        if (response?.data?.code == 0) {
          toast({
            title: `${
              !isTipCommentator ? "Phát" : "Đóng"
            } trực tiếp nhận định thành công`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            `${
              !isTipCommentator ? "Phát" : "Đóng"
            } trực tiếp nhận định thất bại`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }

  const getColor = (status) => {
    switch (status) {
      case STATUS.PENDING:
        return pendingColor;
      case STATUS.APPROVED:
        return approvedColor;
      default:
        return titleColor;
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {order}
            </Text>
          </Flex>
        </Td>
        <Td
          width="100px"
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" width="100%" flexWrap="nowrap">
            <Avatar
              src={avatar ? ROOT_API + avatar : null}
              w="50px"
              borderRadius="12px"
              me="18px"
            />
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {name}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {cdnLinks}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Button
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={() => {
              onUploadThumbnailOpen();
            }}
          >
            <FaUpload />
          </Button>
          <Button
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={() => {
              onUpdatePriorityOpen();
            }}
          >
            <GoListOrdered />
          </Button>
          <IconButton
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={() => {
              onAddCdnLinkOpen();
            }}
          >
            <LinkIcon />
          </IconButton>
          <IconButton
            px="2"
            bg="transparent"
            onClick={isTipCommentator ? handleLiveBet : onLiveMatchOpen}
          >
            {isTipCommentator ? (
              <MdStopScreenShare color="blue" />
            ) : (
              <MdLiveTv color="red" />
            )}
          </IconButton>
          <IconButton
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={onInteractDialogOpen}
          >
            <StarIcon color="blue.400" />
          </IconButton>
          <IconButton
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={onCopy}
          >
            {hasCopied ? (
              <MdDone color="blue.400" />
            ) : (
              <FaCopy color="blue.400" />
            )}
          </IconButton>
          <IconButton
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={onCopyUrl}
          >
            {hasCopiedUrl ? (
              <MdDone color="blue.400" />
            ) : (
              <IoLink color="blue.400" />
            )}
          </IconButton>
          <IconButton
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </IconButton>
        </Td>
      </Tr>
      {/* <KolRegisterDialog
        isOpen={isRegisterOpen}
        onOpen={onRegisterOpen}
        onClose={onRegisterClose}
        fetchData={fetchData}
        data={{
          avatar,
          name,
          id,
          phone,
          email,
          status,
        }}
      /> */}
      {isRegisterOpen && (
        <AddLinkDialog
          data={{ name, cdnLinks }}
          isOpen={isRegisterOpen}
          onOpen={onRegisterOpen}
          onClose={onRegisterClose}
          fetchData={fetchData}
        />
      )}
      {isAddCdnLinkOpen && (
        <AddCdnLinkCustomDialog
          isEdit
          data={{ name, rtmp, cdnM3u8Link, streamKey }}
          isOpen={isAddCdnLinkOpen}
          onOpen={onAddCdnLinkOpen}
          onClose={onAddCdnLinkClose}
          fetchData={fetchData}
        />
      )}
      {isLiveMatchOpen && (
        <UpdateLiveMatch
          isEdit
          data={{
            id,
            name,
            rtmp,
            cdnM3u8Link,
            streamKey,
            isTipCommentator,
            videoDiscuss,
          }}
          isOpen={isLiveMatchOpen}
          onOpen={onLiveMatchOpen}
          onClose={onLiveMatchClose}
          fetchData={fetchData}
        />
      )}
      {isUpdatePriorityOpen && (
        <KolPriorityDialog
          isEdit
          data={{ name, order }}
          kolData={kolData}
          isOpen={isUpdatePriorityOpen}
          onOpen={onUpdatePriorityOpen}
          onClose={onUpdatePriorityClose}
          fetchData={fetchData}
        />
      )}
      {isUploadThumbnailOpen && (
        <UploadThumbnail
          isOpen={isUploadThumbnailOpen}
          onOpen={onUploadThumbnailOpen}
          onClose={onUploadThumbnailClose}
          fetchData={fetchData}
          data={thumbnail}
          username={name}
        />
      )}
      {isInteractDialogOpen && (
        <KolInteractDialog
          isEdit
          data={{ name, numberDislike, numberLike, numberHeart }}
          isOpen={isInteractDialogOpen}
          onOpen={onInteractDialogOpen}
          onClose={onInteractDialogClose}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default KolRow;
