// Chakra imports
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import useAxios from "axios-hooks";
import React, { useState, useEffect, useRef } from "react";
import { TablePagination } from "@trendmicro/react-paginations";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import KolRow from "components/Kol/KolRow";
import KolRegisterDialog from "components/Kol/KolRegisterDialog";
import { initialFilter, ROOT_API, API_ROUTES } from "utils/constant";
import { SearchIcon } from "@chakra-ui/icons";

function Kol() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [filter, setFilter] = useState(initialFilter);
  const {
    isOpen: isRegisterOpen,
    onOpen: onRegisterOpen,
    onClose: onRegisterClose,
  } = useDisclosure();
  const [searchValue, setSearchValue] = useState("");
  const refSearchButton = useRef(null);

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: ROOT_API + API_ROUTES.GET_COMMENTATOR,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Commentators
          </Text>
          <Flex columnGap={3} my={3}>
            <InputGroup>
              <InputRightElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputRightElement>
              <Input
                type="text"
                variant="outline"
                placeholder="Search by name"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyUp={(event) => {
                  if (event.key === "Enter" && event?.target?.value) {
                    const searchBtn = refSearchButton?.current;
                    if (searchBtn !== null) {
                      searchBtn.click();
                    }
                  }
                }}
              />
            </InputGroup>

            <Button
              ref={refSearchButton}
              disabled={!searchValue}
              colorScheme="blue"
              onClick={() => {
                setFilter({
                  ...filter,
                  searchKeyword: searchValue,
                  pageIndex: 0,
                });
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setSearchValue("");
                setFilter({
                  ...filter,
                  searchKeyword: "",
                  pageIndex: 0,
                });
              }}
            >
              Reset
            </Button>
          </Flex>
          {/* <Button
            variant="primary"
            maxH="30px"
            m="10px"
            onClick={onRegisterOpen}
          >
            Add
          </Button> */}
        </CardHeader>
        <CardBody>
          <KolRegisterDialog
            isOpen={isRegisterOpen}
            onOpen={onRegisterOpen}
            onClose={onRegisterClose}
            fetchData={refetch}
            // data={rowDomain}
          />
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th borderColor={borderColor} color="gray.400">
                  Ordinal number
                </Th>
                <Th pl="0px" borderColor={borderColor} color="gray.400">
                  avatar
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Name
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  CDN Links
                </Th>
                {/* <Th borderColor={borderColor} color="gray.400">
                  Email
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Phone
                </Th> */}
                {/* <Th borderColor={borderColor} color="gray.400">
                  Status
                </Th> */}
                <Th borderColor={borderColor}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.data?.map((row, index, arr) => {
                return (
                  <KolRow
                    key={row._id}
                    id={row._id}
                    name={row.username}
                    avatar={row.avatar}
                    thumbnail={row?.thumbnail}
                    status={row.status}
                    email={row.email}
                    phone={row.phone}
                    cdnLinks={row?.cdnM3u8Link}
                    rtmp={row?.rtmp}
                    cdnM3u8Link={row?.cdnM3u8Link}
                    streamKey={row?.streamKey}
                    streamUrl={row?.streamUrl}
                    isTipCommentator={row?.isTipCommentator}
                    order={row?.order}
                    videoDiscuss={row?.videoDiscuss}
                    numberDislike={row?.numberDislike}
                    numberLike={row?.numberLike}
                    numberHeart={row?.numberHeart}
                    isLast={index === arr.length - 1 ? true : false}
                    kolData={data?.data}
                    fetchData={refetch}
                  />
                );
              })}
            </Tbody>
          </Table>
          <Flex justifyContent={"flex-end"}>
            <TablePagination
              type="full"
              page={data?.pagination?.page}
              pageLength={data?.pagination?.pageSize}
              totalRecords={data?.pagination?.count}
              onPageChange={({ page, pageLength }) => {
                console.log(page);
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
              prevPageRenderer={() => <i className="fa fa-angle-left" />}
              nextPageRenderer={() => <i className="fa fa-angle-right" />}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Kol;
