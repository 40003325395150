import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Box,
  Text,
  IconButton,
  Tr,
  useColorModeValue,
  useDisclosure,
  Checkbox,
  useToast,
  Icon,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import SelectedMatchDialog from "./SelectedMatchDialog";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  LockIcon,
  UnlockIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  HamburgerIcon,
  LinkIcon,
} from "@chakra-ui/icons";
import { BiFootball } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Fill, RiEditCircleFill } from "react-icons/ri";
import { IoIosInformationCircle } from "react-icons/io";
import { FaRegPlusSquare } from "react-icons/fa";
import ExpandedRow from "./ExpandedRow";
// import AddKolDialog from "./AddKolDialog";
import { axiosGet } from "utils/api";
import SetHotMatchDialog from "./SetHotMatchDialog";
import ChooseCdnLinkDialog from "./ChooseCdnLinkDialog";
import { axiosPost } from "utils/api";
import {
  LEAGUE_NAMESPACE,
  ROOT_API,
  Roles,
  SITE_URLS,
  SITE_URL_1,
  SITE_URL_2,
} from "utils/constant";
import { API_ROUTES } from "utils/constant";
import CreateMatchDialog from "views/Dashboard/Match/components/CreateMatchDialog";
import UpdateScoreDialog from "./UpdateScoreDialog";
import { MATCH_STATUS } from "utils/constant";
import ChooseCdnLinkAdminDialog from "./ChooseCdnLinkAdminDialog";
import ChooseCommentatorDialog from "./ChooseCommentatorDialog";
import UpdateTimeDialog from "views/Dashboard/MatchCDN/components/UpdateTimeDialog";
import { SITE_URL } from "utils/constant";
import { CSVLink, CSVDownload } from "react-csv";
import FormEditCommentator from "views/Dashboard/SelectedMatches/components/FormEditCommentator";
import MatchCommentatorDetail from "views/Dashboard/SelectedMatches/components/MatchCommentatorDetail";
import { AiOutlineEdit } from "react-icons/ai";
import UpdateResultMatch from "views/Dashboard/MatchCDN/components/UpdateResultMatch";
import UpdateStatusMatch from "views/Dashboard/MatchCDN/components/UpdateStatusMatch";
import LinkMatchSeo from "./LinkMatchSeo";
import AddKolIntoLinkDialog from "./AddKolIntoLinkDialog";

const matchApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_MATCH_INFO;

function MatchRowLive(props) {
  const {
    data,
    isLast,
    fetchData,
    dataKols = null,
    isSelected = false,
    cndLinks = null,
    userRole,
  } = props;
  const textColor = useColorModeValue("gray.600", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [status, setStatus] = useState();
  const [commentator, setCommentator] = useState("");
  useEffect(() => {
    if (data?.namespaceLeague) {
      setStatus(data.namespaceLeague);
    }
  }, [data]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const {
    isOpen: isOpenAddCdn,
    onOpen: onOpenAddCdn,
    onClose: onCloseAddCdn,
  } = useDisclosure();
  const {
    isOpen: isOpenSetHotMatch,
    onOpen: onOpenSetHotMatch,
    onClose: onCloseSetHotMatch,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdateScore,
    onOpen: onOpenUpdateScore,
    onClose: onCloseUpdateScore,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdateTime,
    onOpen: onOpenUpdateTime,
    onClose: onCloseUpdateTime,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdateStatus,
    onOpen: onOpenUpdateStatus,
    onClose: onCloseUpdateStatus,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdateResult,
    onOpen: onOpenUpdateResult,
    onClose: onCloseUpdateResult,
  } = useDisclosure();
  const {
    isOpen: isOpenAddCdnAdmin,
    onOpen: onOpenAddCdnAdmin,
    onClose: onCloseAddCdnAdmin,
  } = useDisclosure();
  const {
    isOpen: isOpenChooseCommentator,
    onOpen: onOpenChooseCommentator,
    onClose: onCloseChooseCommentator,
  } = useDisclosure();
  const {
    isOpen: isOpenChooseLink,
    onOpen: onOpenChooseLink,
    onClose: onCloseChooseLink,
  } = useDisclosure();
  const {
    isOpen: isOpenEditCommentator,
    onOpen: onOpenEditCommentator,
    onClose: onCloseEditCommentator,
  } = useDisclosure();
  const {
    isOpen: isOpenCreateCommentator,
    onOpen: onOpenCreateCommentator,
    onClose: onCloseCreateCommentator,
  } = useDisclosure();
  const {
    isOpen: isOpenDetailCommentator,
    onOpen: onOpenDetailCommentator,
    onClose: onCloseDetailCommentator,
  } = useDisclosure();

  const toast = useToast();
  const [expandedRows, setExpandedRows] = useState([]);
  // function handleRowClick() {
  //   onOpen();
  // }

  // function handleSetHotMatch() {
  //   onOpenSetHotMatch();
  // }

  function handleChooseCdn() {
    onOpenAddCdn();
  }

  const handleOpenClick = async () => {
    let msg = "Are you to open access the live match?";
    if (data.isCommentedBeforeGame) {
      msg = "Are you to close access the live match?";
    }
    if (window.confirm(msg)) {
      const matchData = {
        matchId: data.matchId,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.OPEN_LINK_LIVE,
          matchData
        );
        if (response?.data?.code == 0) {
          toast({
            title: data.isCommentedBeforeGame
              ? "Close Live Match Successfully"
              : "Open Live Match Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Update Live Match Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const handleDeleteClick = async () => {
    if (window.confirm("Are you to remove the live match?")) {
      const matchData = {
        matchId: data.matchId,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.REMOVE_LINK_LIVE,
          matchData
        );
        if (response?.data?.code == 0) {
          toast({
            title: "Delete Live Match Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete Live Match Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const handleDeleteMatchClick = async () => {
    if (window.confirm("Are you to remove the match?")) {
      const matchData = {
        id: data._id,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.DELETE_MATCH,
          matchData
        );
        if (response?.data?.code == 0) {
          toast({
            title: "Delete Match Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete Match Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const handleDeleteCommentatorClick = async () => {
    if (window.confirm("Are you to remove commentator the match?")) {
      const matchData = {
        id: data._id,
      };
      // try {
      //   const response = await axiosPost(
      //     ROOT_API + API_ROUTES.DELETE_MATCH,
      //     matchData
      //   );
      //   if (response?.data?.code == 0) {
      //     toast({
      //       title: "Delete Match Successfully",
      //       status: "success",
      //       duration: 9000,
      //       isClosable: true,
      //     });
      //     fetchData();
      //   }
      // } catch (error) {
      //   toast({
      //     title:
      //       error?.response?.data?.errors?.errors[0]?.msg ||
      //       error?.response?.data?.msg ||
      //       "Delete Match Fail",
      //     status: "error",
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // }
    }
  };
  const handleChangeStatus = async (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    if (
      window.confirm(
        `Are you sure to add SEO to "${
          newStatus ? LEAGUE_NAMESPACE[newStatus] : "reset link"
        } " ?`
      )
    ) {
      const matchData = {
        matchId: data.matchId,
        league: newStatus,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.ADD_SEO_LEAGUE,
          matchData
        );
        if (response?.data?.code == 0) {
          toast({
            title: "Update SEO Match Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Update SEO Match Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const toggleRow = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const handleSetHotMatch = async () => {
    onOpenChooseCommentator();
    // if (window.confirm("Are you sure to set hot this match?")) {
    //   const matchData = {
    //     matchId: data.matchId,
    //   };
    //   try {
    //     const response = await axiosPost(
    //       ROOT_API + API_ROUTES.SET_HOT_MATCH,
    //       matchData
    //     );
    //     if (response?.data?.code === 0) {
    //       toast({
    //         title: "Set hot Successfully",
    //         status: "success",
    //         duration: 9000,
    //         isClosable: true,
    //       });
    //       fetchData();
    //     }
    //   } catch (error) {
    //     toast({
    //       title:
    //         error?.response?.data?.errors?.errors[0]?.msg ||
    //         error?.response?.data?.msg ||
    //         "Set hot Fail",
    //       status: "error",
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   }
    // }
  };

  const handleCopyUrl = (slug) => {
    const siteUrls = SITE_URLS;
    const data = siteUrls.map((i) => [`${i}tran-dau/${slug}`]);
    // const siteUrl_1 = SITE_URL_1;
    // const siteUrl_2 = SITE_URL_2;
    // const matchUrl = `${siteUrl}tran-dau/${slug}`;
    // const matchUrl_1 = `${siteUrl_1}tran-dau/${slug}`;
    // const matchUrl_2 = `${siteUrl_2}tran-dau/${slug}`;
    // const csvData = data;
    return data;
    // navigator.clipboard.writeText(matchUrl);
  };

  const handleSetPriority = async () => {
    onOpenChooseLink();
  };
  const handleSetShowVideo = async (matchId) => {
    if (window.confirm("Are you sure to open video intro for match?")) {
      const matchData = {
        matchId: matchId,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.SET_OPEN_MATCH,
          matchData
        );
        if (response?.data?.code === 0) {
          toast({
            title: "Change Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Change Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const renderCommentators = () => {
    const commentators = data?.commentators;
    if (commentators?.length) {
      return commentators.map((c, i) => (
        <Text
          key={i}
          onClick={() => {
            setCommentator(c)
            onOpen()
          }}
          cursor="pointer"
          color="#027fff"
          textDecoration="underline"
        >
          {c}
          <LinkMatchSeo
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            match={data}
            cmt={commentator}
          />
        </Text>
      ));
    }
    return (
      <Text
        onClick={onOpen}
        cursor="pointer"
        color="#027fff"
        textDecoration="underline"
      >
        <LinkIcon />
        <LinkMatchSeo
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          match={data}
        />
      </Text>
    );
  };
  const getLinkPriority = useCallback(() => {
    const commentator = data?.commentatorIds?.find(
      (i) => i._id === data?.priorityLink
    );
    if (commentator) return commentator.name;
    return null;
  }, [data?.priority]);
  return (
    <>
      {/* <Flex maxW="full"> */}
      <Tr>
        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
          w="200px"
        >
          <Flex direction="col" gap={2}>
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {data?.matchId}
            </Text>
            {data?.isHot && (
              <Box
                background="red"
                color="white"
                borderRadius="10"
                fontSize="10"
                px="5px"
              >
                Live
              </Box>
            )}
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor}>
            {data?.home_name || data?.localteam_title} /{" "}
            {data?.away_name || data?.visitorteam_title}
          </Text>
        </Td>

        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
          w="200px"
        >
          <Text fontSize="md" color={textColor}>
            {/* {moment
              .utc( data?.time || data?.date)
              .local()
              .format("HH:mm")} */}
            {/* {moment(data?.startTime, "DD-MM-YYYY HH:mm").format("HH:mm")} */}
            {data?.startTime}
          </Text>
        </Td>
        <Td>
          {/* <Flex>{data?.isHot ? data?.commentators?.join(", ") : ""}</Flex> */}
          <Flex columnGap={2}>{renderCommentators()}</Flex>
        </Td>
        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
          w="200px"
        >
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList px={2} py={1} display="flex" alignItems="center">
              {(userRole === Roles.ADMIN || userRole === Roles.MANAGER) && (
                <Td
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : null}
                >
                  <Flex gap={4}>
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      <Button
                        px="2"
                        bg="transparent"
                        onClick={onOpenUpdateResult}
                      >
                        <Icon as={AiOutlineEdit} cursor="pointer" />
                      </Button>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      <Button
                        px="2"
                        bg="transparent"
                        onClick={onOpenUpdateStatus}
                      >
                        <Icon as={RiEditCircleFill} cursor="pointer" />
                      </Button>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      <Button
                        px="2"
                        bg="transparent"
                        onClick={onOpenUpdateTime}
                      >
                        <Icon as={BiFootball} cursor="pointer" />
                      </Button>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      <Checkbox
                        id={data?._id}
                        value={data?.isHot}
                        isChecked={data?.isHot}
                        onChange={handleSetHotMatch}
                      />
                      <Text
                        fontSize="md"
                        color={data?.isHot ? "red" : "green"}
                        fontWeight="bold"
                      >
                        HOT
                      </Text>
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      {data?.priority === 1 ? (
                        <Button
                          px="2"
                          bg="transparent"
                          onClick={() => handleSetPriority()}
                        >
                          <ArrowDownIcon />
                          <Text fontSize="md" color="red" fontWeight="bold">
                            {getLinkPriority()}
                          </Text>
                        </Button>
                      ) : (
                        <Button
                          px="2"
                          bg="transparent"
                          onClick={() => handleSetPriority()}
                        >
                          <ArrowUpIcon />
                        </Button>
                      )}
                    </Flex>
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      {data?.adminSettingIsLive ? (
                        <Button
                          px="2"
                          bg="transparent"
                          onClick={() => handleSetShowVideo(data.matchId)}
                        >
                          <Text fontSize="md" color="red" fontWeight="bold">
                            Live
                          </Text>
                        </Button>
                      ) : (
                        <Button
                          px="2"
                          bg="transparent"
                          onClick={() => handleSetShowVideo(data.matchId)}
                        >
                          Show Video
                        </Button>
                      )}
                    </Flex>
                    {/* {userRole === Roles.ADMIN && (
                <Flex flexDirection={"row"} alignItems={"center"} columnGap={2}>
                  <Checkbox
                    id={data?._id}
                    value={data?.isLive}
                    isChecked={data?.isLive}
                    onChange={onOpenAddCdnAdmin}
                    disabled={data?.shortStatus === "FT"}
                  />
                  <Text
                    fontSize="md"
                    color={isSelected || data?.isLive ? "red" : "green"}
                    fontWeight="bold"
                  >
                    {isSelected || data?.isLive ? "LIVE" : "LIVE"}
                  </Text>
                </Flex>
              )} */}
                    {/* <Button
                px="2"
                bg="transparent"
                onClick={() => handleCopyUrl(data.slug)}
              >
                <CopyIcon />
              </Button> */}
                    {/* <CSVDownload data={handleCopyUrl(data.slug)} target="_blank" /> */}
                    {/* <CSVLink
                      data={handleCopyUrl(data.slug)}
                      filename={`${data.slug}.csv`}
                      onClick={() => {
                        console.log("You click the link"); // 👍🏻 Your click handling logic
                      }}
                    >
                      <CopyIcon />
                    </CSVLink> */}
                    {/* <Button onClick={() => onOpen()}>link</Button> */}
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      <Select onChange={handleChangeStatus} value={status}>
                        <option value={""}></option>
                        {Object.keys(LEAGUE_NAMESPACE).map((key) => (
                          <option key={key} value={key}>
                            {LEAGUE_NAMESPACE[key]}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                  </Flex>
                </Td>
              )}

              {userRole === Roles.COMMENTATOR && (
                <Td
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : null}
                >
                  <Flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    columnGap={2}
                  >
                    <Checkbox
                      id={data?._id}
                      value={data?.isLive}
                      isChecked={data?.isLive}
                      onChange={handleChooseCdn}
                      disabled={data?.shortStatus === "FT"}
                    />
                    <Text
                      fontSize="md"
                      color={isSelected || data?.isLive ? "red" : "green"}
                      fontWeight="bold"
                    >
                      {isSelected || data?.isLive ? "LIVE" : "LIVE"}
                    </Text>
                  </Flex>
                </Td>
              )}
              {!isSelected && data.isMini && (
                <Td
                  borderColor={borderColor}
                  borderBottom={isLast ? "none" : null}
                >
                  <>
                    {/* <Button
                p="0px"
                bg="transparent"
                variant="no-effects"
                onClick={onOpenCreate}
                mr={2}
              >
                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                  <EditIcon />
                </Text>
              </Button> */}
                    <Button
                      p="0px"
                      bg="transparent"
                      variant="no-effects"
                      onClick={handleDeleteMatchClick}
                      disabled={data?.shortStatus !== "NS"}
                    >
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        <DeleteIcon />
                      </Text>
                    </Button>
                  </>
                </Td>
              )}

              {isSelected ? (
                <>
                  <Td
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : null}
                  >
                    <Flex
                      flexDirection={"row"}
                      alignItems={"center"}
                      columnGap={2}
                    >
                      <Checkbox
                        id={data?._id}
                        value={data?.isHot}
                        isChecked={data?.isHot}
                        onChange={handleSetHotMatch}
                        disabled={data?.shortStatus === "FT"}
                      />
                      <Text
                        fontSize="md"
                        color={data?.isHot ? "red" : "green"}
                        fontWeight="bold"
                      >
                        Feature
                      </Text>
                    </Flex>
                  </Td>
                  <Td
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : null}
                  >
                    <Flex direction={"row"} columnGap={1}>
                      <Box as="tr">
                        <IconButton
                          icon={
                            expandedRows?.includes(data?.fixture?.id) ? (
                              <ChevronDownIcon />
                            ) : (
                              <ChevronUpIcon />
                            )
                          }
                          onClick={() => toggleRow(data?.fixture?.id)}
                          aria-label={
                            expandedRows?.includes(data?.fixture?.id)
                              ? "Hide details"
                              : "Show details"
                          }
                        />
                      </Box>
                    </Flex>
                  </Td>
                  <Td
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : null}
                  >
                    <Flex direction={"row"} columnGap={1}>
                      {data?.isMini && data?.shortStatus !== "NS" ? (
                        <Button
                          px="2"
                          bg="transparent"
                          onClick={onOpenUpdateScore}
                        >
                          <Icon as={BiFootball} cursor="pointer" />
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        px="2"
                        bg="transparent"
                        onClick={handleOpenClick}
                        disabled={data?.shortStatus !== "NS"}
                      >
                        {!data?.isCommentedBeforeGame ? (
                          <UnlockIcon />
                        ) : (
                          <LockIcon />
                        )}
                      </Button>
                      <Button
                        px="2"
                        bg="transparent"
                        onClick={handleDeleteClick}
                        disabled={data?.shortStatus !== "NS"}
                      >
                        <DeleteIcon />
                      </Button>
                    </Flex>
                  </Td>
                  <Td
                    borderColor={borderColor}
                    borderBottom={isLast ? "none" : null}
                  >
                    <Flex alignItems={"center"}>
                      <Text
                        fontSize="md"
                        fontWeight="bold"
                        pr={2}
                        maxW={120}
                        minW={120}
                      >
                        {data?.commentators.map((item) => item).join(", ")}
                      </Text>
                      <Button
                        px="1"
                        bg="transparent"
                        onClick={onOpenEditCommentator}
                      >
                        <FiEdit />
                      </Button>
                      <Button
                        px="1"
                        bg="transparent"
                        onClick={handleDeleteCommentatorClick}
                      >
                        <RiDeleteBin6Fill />
                      </Button>
                      <Button
                        px="1"
                        bg="transparent"
                        onClick={onOpenDetailCommentator}
                      >
                        <IoIosInformationCircle />
                      </Button>
                      {data?.commentators?.length < 5 && (
                        <Button
                          px="1"
                          bg="transparent"
                          onClick={onOpenCreateCommentator}
                        >
                          <FaRegPlusSquare />
                        </Button>
                      )}
                    </Flex>
                  </Td>
                </>
              ) : (
                ""
              )}
            </MenuList>
          </Menu>
        </Td>
      </Tr>
      {/* {isOpen && (
        <LinkMatchSeo
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          match={data}
        />
      )} */}
      {isOpenUpdateResult && (
        <UpdateResultMatch
          matchDetail={data}
          isOpen={isOpenUpdateResult}
          onOpen={onOpenUpdateResult}
          onClose={onCloseUpdateResult}
          fetchData={fetchData}
        />
      )}
      {isOpenUpdateStatus && (
        <UpdateStatusMatch
          matchDetail={data}
          isOpen={isOpenUpdateStatus}
          onOpen={onOpenUpdateStatus}
          onClose={onCloseUpdateStatus}
          fetchData={fetchData}
        />
      )}
      {isOpenAddCdn && (
        <ChooseCdnLinkDialog
          isOpen={isOpenAddCdn}
          onOpen={onOpenAddCdn}
          onClose={onCloseAddCdn}
          fetchData={fetchData}
          dataCurrentMatch={data}
          cndLinks={cndLinks}
        />
      )}
      {isOpenAddCdnAdmin && (
        <ChooseCdnLinkAdminDialog
          isOpen={isOpenAddCdnAdmin}
          onOpen={onOpenAddCdnAdmin}
          onClose={onCloseAddCdnAdmin}
          fetchData={fetchData}
          dataCurrentMatch={data}
          cndLinks={cndLinks}
        />
      )}
      {isSelected && (
        <>
          <SetHotMatchDialog
            isOpen={isOpenSetHotMatch}
            onOpen={onOpenSetHotMatch}
            onClose={onCloseSetHotMatch}
            fetchData={fetchData}
            dataCurrentMatch={data}
          />
        </>
      )}
      {expandedRows.includes(data?.fixture?.id) && (
        <Tr>
          <Td colSpan={8}>
            <ExpandedRow data={data} refetch={fetchData} dataKols={dataKols} />
          </Td>
        </Tr>
      )}
      {isOpenCreate && (
        <CreateMatchDialog
          matchDetail={data}
          isOpen={isOpenCreate}
          onOpen={onOpenCreate}
          onClose={onCloseCreate}
          fetchData={fetchData}
        />
      )}
      {isOpenUpdateScore && (
        <UpdateScoreDialog
          matchDetail={data}
          isOpen={isOpenUpdateScore}
          onOpen={onOpenUpdateScore}
          onClose={onCloseUpdateScore}
          fetchData={fetchData}
        />
      )}
      {isOpenUpdateTime && (
        <UpdateTimeDialog
          matchDetail={data}
          isOpen={isOpenUpdateTime}
          onOpen={onOpenUpdateTime}
          onClose={onCloseUpdateTime}
          fetchData={fetchData}
        />
      )}
      {isOpenChooseCommentator && (
        <ChooseCommentatorDialog
          matchDetail={data}
          isOpen={isOpenChooseCommentator}
          onOpen={onOpenChooseCommentator}
          onClose={onCloseChooseCommentator}
          fetchData={fetchData}
        />
      )}
      {isOpenChooseLink && (
        <AddKolIntoLinkDialog
          isOpen={isOpenChooseLink}
          onOpen={onOpenChooseLink}
          onClose={onCloseChooseLink}
          fetchData={fetchData}
          dataCurrentMatch={data}
          // dataKols={dataKols}
          // link={selectedLink}
        />
      )}
      {isOpenEditCommentator && (
        <FormEditCommentator
          matchDetail={data}
          isOpen={isOpenEditCommentator}
          onOpen={onOpenEditCommentator}
          onClose={onCloseEditCommentator}
          fetchData={fetchData}
        />
      )}
      {isOpenCreateCommentator && (
        <FormEditCommentator
          matchDetail={null}
          isOpen={isOpenCreateCommentator}
          onOpen={onOpenCreateCommentator}
          onClose={onCloseCreateCommentator}
          fetchData={fetchData}
        />
      )}
      {isOpenDetailCommentator && (
        <MatchCommentatorDetail
          matchDetail={data}
          isOpen={isOpenDetailCommentator}
          onOpen={onOpenDetailCommentator}
          onClose={onCloseDetailCommentator}
        />
      )}
    </>
  );
}

export default MatchRowLive;
