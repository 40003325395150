import React, { useState, useEffect } from "react";

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  useColorModeValue,
  Flex,
  List,
  ListItem,
  Divider,
  Avatar,
  Checkbox,
  FormLabel,
  CheckboxGroup,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";

import { axiosPost } from "../../utils/api";
import { API_ROUTES, ROOT_API } from "utils/constant";


const AddKolIntoLinkDialog = (props) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const textColor = useColorModeValue("gray.500", "white");
  const { isOpen, onOpen, onClose, fetchData, dataCurrentMatch } = props;
  const commentators = dataCurrentMatch?.commentatorIds;
  const link = dataCurrentMatch?.priorityLink;
  const [selectedKol, setSelectedKol] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (link) {
      setSelectedKol(link);
    }
  }, [link]);

  const clickChooseKolButton = async (isActive = true) => {
    setLoading(true);
    const matchData = {
      matchId: dataCurrentMatch?.matchId,
      priority: isActive ? 1 : 9999,
      priorityLink: isActive ? selectedKol : null,
    };

    try {
      const response = await axiosPost(
        ROOT_API + API_ROUTES.SET_PRIORITY_MATCH,
        matchData
      );
      if (response?.data?.code === 0) {
        toast({
          title: `${isActive ? "Ưu tiên" : "Hủy ưu tiên" } trận đấu thành công.`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchData();
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Thiết lập ưu tiên thất bại",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const onSelectKol = (value) => {
    setSelectedKol(value);
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Chọn BLV</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <List spacing={3}>
              <RadioGroup
                onChange={onSelectKol}
                value={selectedKol}
                name="kol-radio"
              >
                {commentators.length
                  ? commentators.map((cmt) => {
                      return (
                        <>
                          <Flex direction={"row"} columnGap={4} m={2} w="full">
                            {/* <Checkbox
                              value={kol._id}
                              id={`r-${kol._id}`}
                              onChange={onSelectKol}
                            > */}
                            <Radio
                              value={cmt._id}
                              id={`r-${cmt._id}`}
                            />
                            <FormLabel htmlFor={`r-${cmt._id}`} w={"full"}>
                              <Flex
                                direction={"row"}
                                columnGap={4}
                                m={2}
                                cursor={"pointer"}
                              >
                                <Avatar
                                  src={cmt.avatar}
                                  w="50px"
                                  h="50px"
                                  borderRadius={12}
                                />
                                <ListItem key={cmt._id} color={textColor}>
                                  {cmt?.name}
                                </ListItem>
                              </Flex>
                            </FormLabel>
                            {/* </Checkbox> */}
                          </Flex>
                          <Divider />
                        </>
                      );
                    })
                  : ""}
              </RadioGroup>
            </List>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
                setSelectedKol("");
              }}
            >
              Đóng
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={() => {
                clickChooseKolButton(true);
              }}
              disabled={!selectedKol || loading}
            >
              Ưu tiên
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                clickChooseKolButton(false);
              }}
          
            >
              Hủy Ưu tiên
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddKolIntoLinkDialog;
