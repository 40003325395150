import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  useToast,
  Flex,
  Text,
  useColorModeValue,
  Switch,
  Divider,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { isEmpty, pick, uniqBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import InputController from "components/Form/InputController";
import {
  ValidateMessage,
  ROOT_API,
  API_ROUTES,
  MISSION_TYPE,
  MISSION_DAILY,
  DAY_OF_WEEK,
} from "utils/constant";
import SelectController from "components/Form/SelectController";
import { mappingMiniLeagueOption } from "utils/mapping";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { CloseIcon } from "@chakra-ui/icons";

const CreateMissionDialog = ({ seasonDetail, onClose, refetchData }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const history = useHistory();
  const cancelRef = useRef();
  const toast = useToast();
  const params = useParams();
  const { id } = params || {};
  const [leagueOption, setLeagueOption] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [{ loading: createLoading }, createMissionApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CREATE_MISSIONS,
    },
    { manual: true }
  );
  const [{ loading: updateLoading }, updatePostApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.UPDATE_MISSIONS,
    },
    { manual: true }
  );
  const [, publicPostApi] = useAxios(
    {
      method: "post",
      url: `${ROOT_API}${API_ROUTES.STATUS_MISSION}`,
    },
    { manual: true }
  );
  const [{ loading: loadingPostDetail }, getPostDetailApi] = useAxios(
    {
      url: `${ROOT_API}${API_ROUTES.MISSIONS}/${id}`,
    },
    { manual: true }
  );
  const [{ data: leagueData }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.LEAGUES_FILTER,
      params: { isActive: true },
    },
    {
      useCache: false,
    }
  );

  useEffect(
    () => () => {
      reset();
    },
    []
  );

  useEffect(() => {
    if (!!id) {
      getPostDetailApi().then((res) => {
        const data = res?.data?.data;

        reset({
          ...pick(data, [
            "missionName",
            "point",
            "numberTime",
            "minutes",
            "times",
            "bonus",
          ]),
          type: Object.keys(MISSION_TYPE)
            .map((key) => ({
              label: MISSION_TYPE[key],
              value: key,
            }))
            .find((item) => item.value === data?.type),
          dailyType: Object.keys(MISSION_DAILY)
            .map((key) => ({
              label: MISSION_DAILY[key],
              value: key,
            }))
            .find((item) => item.value === data?.dailyType),
          dayOfWeek: [
            { label: "Thứ 2", value: 1 },
            { label: "Thứ 3", value: 2 },
            { label: "Thứ 4", value: 3 },
            { label: "Thứ 5", value: 4 },
            { label: "Thứ 6", value: 5 },
            { label: "Thứ 7", value: 6 },
            { label: "Chủ nhật", value: 7 },
          ].find((item) => item.value === data?.dayOfWeek),
        });
        setIsPublic(data?.isShow);
      });
    }
  }, [id]);

  useEffect(() => {
    setLeagueOption(mappingMiniLeagueOption(leagueData?.data));
  }, [leagueData]);

  const schema = yup.object().shape({
    missionName: yup
      .string()
      .nullable()
      .when("type", {
        is: (type) => (type?.value !== "DAILY" ? true : false),
        then: () => yup.string().nullable().required(ValidateMessage.required),
      }),
    type: yup.object().nullable().required(ValidateMessage.required),
    // bonus: yup.string().nullable().required(ValidateMessage.required),
    // times: yup
    //   .string()
    //   .nullable()
    //   .when("type", {
    //     is: (type) => (type?.value === "DAILY" ? true : false),
    //     then: () => yup.string().nullable().required(ValidateMessage.required),
    //   }),
    minutes: yup.string().nullable().notRequired(),
    other: yup.array().of(
      yup.object().shape({
        point: yup.string().required(ValidateMessage.required),
        period: yup.mixed().required(ValidateMessage.required),
      })
    ),
    otherDaily: yup.array().of(
      yup.object().shape({
        point: yup.string().required(ValidateMessage.required),
        dayOfWeek: yup.mixed().required(ValidateMessage.required),
      })
    ),
    _otherDaily: yup.array().of(
      yup.object().shape({
        point: yup.string().required(ValidateMessage.required),
        missionName: yup.string().required(ValidateMessage.required),
        times: yup.string().required(ValidateMessage.required),
        dailyType: yup.mixed().required(ValidateMessage.required),
      })
    ),
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      missionName: "",
      type: undefined,
      point: "",
      minutes: "",
      dayOfWeek: undefined,
      times: "",
      dailyType: undefined,
    },
  });
  const type = watch("type");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "other",
  });

  const {
    fields: fieldsDaily,
    append: appendDaily,
    remove: removeDaily,
  } = useFieldArray({
    control,
    name: "otherDaily",
  });

  const {
    fields: _fieldsDaily,
    append: _appendDaily,
    remove: _removeDaily,
  } = useFieldArray({
    control,
    name: "_otherDaily",
  });

  const handleSuccess = () => {
    refetchData?.();
    toast({
      title: `${seasonDetail?._id ? "Edit" : "Create"} Mission Successfully`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    history.goBack();
  };

  const handleError = (error) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `${seasonDetail?._id ? "Edit" : "Create"} Mission Fail`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = (dataForm) => {
    const payload = {
      type: dataForm?.type?.value,
      missionName:
        dataForm?.type?.value !== "DAILY" ? dataForm?.missionName : undefined,
      ...(dataForm?.other?.length > 0 && {
        missions: dataForm?.other?.map((item) => {
          return {
            point: Number(item.point),
            minutes: Number(item.period.value),
          };
        }),
      }),
      ...(dataForm?._otherDaily?.length > 0 && {
        missions: dataForm?._otherDaily?.map((item) => {
          return {
            dailyType: item.dailyType.value,
            missionName: item.missionName,
            point: Number(item.point),
            times: Number(item.times),
          };
        }),
      }),
      ...(dataForm?.otherDaily?.length > 0 && {
        missions: dataForm?.otherDaily?.map((item) => {
          return {
            dayOfWeek: item.dayOfWeek.value,
            point: Number(item.point),
          };
        }),
      }),
    };
    if (id) {
      updatePostApi({
        data: { ...payload, missionId: id },
      })
        .then(() => {
          handleSuccess();
        })
        .catch((error) => {
          handleError(error);
        });

      return;
    }

    createMissionApi({
      data: payload,
    })
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handlePublicPost = (status) => {
    publicPostApi({
      data: {
        missionId: id,
      },
    })
      .then(() => {
        toast({
          title: "Change Status Mission Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Change Status Mission Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    setIsPublic(status);
  };

  const handleGenFieldChild = useCallback(() => {
    append({ point: "", period: undefined });
  }, [append]);

  const handleGenFieldChildDaily = useCallback(() => {
    appendDaily({ dayOfWeek: undefined, point: "" });
  }, [appendDaily]);

  const _handleGenFieldChildDaily = useCallback(() => {
    _appendDaily({ dailyType: undefined, point: "" });
  }, [_appendDaily]);
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Flex justifyContent="space-between">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {`${id ? "Edit" : "Create"} Mission`}
            </Text>
            {id && (
              <Switch
                size="md"
                isChecked={isPublic}
                onChange={(e) => handlePublicPost(e.target.checked)}
              />
            )}
          </Flex>
        </CardHeader>
        <CardBody pb={4}>
          <form>
            <SelectController
              control={control}
              name="type"
              label="Loại"
              isRequired
              options={Object.keys(MISSION_TYPE).map((key) => ({
                label: MISSION_TYPE[key],
                value: key,
              }))}
              isDisabled={id}
              onChange={() => {
                reset((values) => ({
                  ...values,
                  missionName: "",
                }));
                fields?.forEach((field, index) => {
                  remove(field?.id);
                });
                fieldsDaily?.forEach((field, index) => {
                  removeDaily(field?.id);
                });
                _fieldsDaily?.forEach((field, index) => {
                  _removeDaily(field?.id);
                });
              }}
            />
            {type?.value !== "DAILY" && (
              <InputController
                control={control}
                name="missionName"
                label="Tên nhiệm vụ"
                isRequired
                styleContainer={{ pt: "4" }}
              />
            )}
            {/* <InputController
              control={control}
              name="point"
              label="Điểm"
              type="number"
              isRequired
              styleContainer={{ pt: "4" }}
            /> */}
            {type?.value === "DAILY" && (
              <>
                <Flex alignItems={"end"} mt={4} gap={4}>
                  <Button onClick={_handleGenFieldChildDaily}>Thêm điểm thưởng</Button>
                </Flex>
                {!isEmpty(_fieldsDaily) && (
                  <Box
                    borderWidth="1px"
                    borderStyle="dashed"
                    borderRadius="12px"
                    padding="16px"
                    mt={4}
                  >
                    {_fieldsDaily.map((field, index) => {
                      return (
                        <Flex key={field?.id} gap={4} alignItems="end" mb={2}>
                          <SelectController
                            styleContainer={{ pt: "4" }}
                            control={control}
                            // isRequired
                            name={`_otherDaily.${index}.dailyType`}
                            label="Các loại nhiệm vụ"
                            options={Object.keys(MISSION_DAILY).map((key) => ({
                              label: MISSION_DAILY[key],
                              value: key,
                            }))}
                            menuPlacement="top"
                          />
                          <InputController
                            control={control}
                            name={`_otherDaily.${index}.point`}
                            label={`Điểm thưởng`}
                          />
                          <InputController
                            control={control}
                            name={`_otherDaily.${index}.missionName`}
                            label="Tên nhiệm vụ"
                            isRequired
                            styleContainer={{ pt: "4" }}
                          />
                          <InputController
                            control={control}
                            name={`_otherDaily.${index}.times`}
                            label="Số lần"
                            type="number"
                            // isRequired
                            styleContainer={{ pt: "4" }}
                          />
                          <IconButton
                            onClick={() => {
                              _removeDaily(index);
                            }}
                          >
                            <CloseIcon boxSize={3} />
                          </IconButton>
                        </Flex>
                      );
                    })}
                  </Box>
                )}
              </>
            )}
            {type?.value === "COUNTDOWN" && (
              <>
                <Flex alignItems={"end"} mt={4} gap={4}>
                  {/* <SelectController
                    control={control}
                    // isRequired
                    name="period"
                    label="Các mốc thời gian"
                    options={Array.from({ length: 91 }, (_, i) => {
                      return {
                        label: `${i} phút`,
                        value: i,
                      };
                    })}
                    isMulti
                    menuPlacement="top"
                  /> */}
                  <Button onClick={handleGenFieldChild}>Thêm điểm thưởng</Button>
                </Flex>
                {!isEmpty(fields) && (
                  <Box
                    borderWidth="1px"
                    borderStyle="dashed"
                    borderRadius="12px"
                    padding="16px"
                    mt={4}
                  >
                    {fields.map((field, index) => {
                      return (
                        <Flex key={field?.id} gap={4} alignItems="end" mb={2}>
                          <InputController
                            control={control}
                            name={`other.${index}.point`}
                            label={`Điểm thưởng`}
                          />
                          <SelectController
                            control={control}
                            name={`other.${index}.period`}
                            label="Các mốc thời gian"
                            options={Array.from({ length: 91 }, (_, i) => {
                              return {
                                label: `${i} phút`,
                                value: i,
                              };
                            })}
                            menuPlacement="top"
                          />
                          <IconButton
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <CloseIcon boxSize={3} />
                          </IconButton>
                        </Flex>
                      );
                    })}
                  </Box>
                )}
              </>
            )}
            {/* {type?.value === "COUNTDOWN" && (
              <InputController
                control={control}
                name="minutes"
                label="Số phút xem live"
                type="number"
                // isRequired
                styleContainer={{ pt: "4" }}
              />
            )} */}
            {type?.value === "ROLL_CALL" && (
              <>
                <Flex alignItems={"end"} mt={4} gap={4}>
                  <Button onClick={handleGenFieldChildDaily}>
                  Thêm điểm thưởng
                  </Button>
                </Flex>
                {!isEmpty(fieldsDaily) && (
                  <Box
                    borderWidth="1px"
                    borderStyle="dashed"
                    borderRadius="12px"
                    padding="16px"
                    mt={4}
                  >
                    {fieldsDaily.map((field, index) => {
                      return (
                        <Flex key={field?.id} gap={4} alignItems="end" mb={2}>
                          <SelectController
                            styleContainer={{ pt: "4" }}
                            control={control}
                            // isRequired
                            name={`otherDaily.${index}.dayOfWeek`}
                            label="Ngày trong tuần"
                            options={[
                              { label: "Thứ 2", value: 1 },
                              { label: "Thứ 3", value: 2 },
                              { label: "Thứ 4", value: 3 },
                              { label: "Thứ 5", value: 4 },
                              { label: "Thứ 6", value: 5 },
                              { label: "Thứ 7", value: 6 },
                              { label: "Chủ nhật", value: 7 },
                            ]}
                            menuPlacement="top"
                          />
                          <InputController
                            control={control}
                            name={`otherDaily.${index}.point`}
                            label={`Điểm thưởng`}
                          />
                          <IconButton
                            onClick={() => {
                              removeDaily(index);
                            }}
                          >
                            <CloseIcon boxSize={3} />
                          </IconButton>
                        </Flex>
                      );
                    })}
                  </Box>
                )}
              </>
            )}
          </form>
          <Flex pt={4} alignItems="flex-end" justifyContent="flex-end">
            <Button
              ref={cancelRef}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              isLoading={createLoading || updateLoading}
              onClick={handleSubmit(onSubmit)}
            >
              OK
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CreateMissionDialog;
